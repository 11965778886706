body {
  margin: 0 !important;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
  'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
  sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
  -moz-osx-font-smoothing: grayscale !important;
}

.container{
  height: 80% !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

.LOCAL{
  background-color: #45d52c;
  color: white;
  padding: 8px;
  /* font-weight: bold; */
  border-radius: 20px;
  margin-right: 20px;
}

.DEV{
  background-color: #2c73d5;
  color: white;
  padding: 12px;
  font-weight: bold;
  border-radius: 10px;
  margin-right: 20px;
}

.PROD{
  background-color: #d5572c;
  color: white;
  padding: 12px;
  font-weight: bold;
  border-radius: 10px;
  margin-right: 20px;
}

.ActionButtons{
  margin-left: 9px !important;
  margin-right: 9px !important;
  font-size: large !important;
}

.QrButton{
  margin-right: 7.5px;
  font-size: large;
}

/* table */
.ant-table {
  font-size: 14px !important;
}

/* row data */
.ant-table-tbody > tr > td {
  height: 6px !important;
  padding: 6px !important;
}

.ant-table-thead > tr > th {
  height: 5px !important;
  padding: 4px !important;
}

.ant-tag {
  font-weight: bold !important;
  font-size: 15px !important;
  border-width: 3px !important;
  border-radius: 4px !important;
}

.ant-table-column-sorters {
  padding: 5px 5px !important;
}

.activeCard {
  border-color: blue !important;
  border-width: 1.5px !important;
}

.clickRowStyl{
  font-weight: bold !important;
  -webkit-filter: contrast(90%) !important;
          filter: contrast(90%) !important;
}

.ant-table-tbody>.clickRowStyl:hover>td{
  font-weight: bold !important;
  -webkit-filter: contrast(90%) !important;
          filter: contrast(90%) !important;
}

.ant-table-tbody>tr{
  cursor: pointer !important;
}

.ant-table-pagination.ant-pagination {
  margin: 10px 0 0 0 !important;
}

.events {
   margin: 0;
   padding: 0;
   list-style: none;
}

.events .ant-badge-status {
  width: 100%;
  overflow: hidden;
  font-size: 12px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.notes-month {
  font-size: 28px;
  text-align: center;
}
.notes-month section {
         font-size: 28px;
}

.trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

.trigger:hover {
  color: #1890ff;
}

.logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.3);
  margin: 16px;
}

.site-layout .site-layout-background {
  background: #fff;
  height: auto;
}

.ant-image-img{
  display: inline !important;
}

.fc-h-event > .fc-event-main {
  width: 130px;
  white-space: normal;
}

.listhover:hover {
  background-color: #f0f0ff; /* Change background color on hover */
}

.ant-table-wrapper {
    height: 100% !important;
}
.ant-spin-nested-loading {
    height: 100% !important;
}
.ant-spin-container {
    height: 100% !important;
    display: flex !important;
    flex-flow: column nowrap !important;
}
.ant-table {
    flex: auto !important;
    overflow: hidden !important;
}
.ant-table-container {
    height: 100% !important;
    display: flex !important;
    flex-flow: column nowrap !important;
}
.ant-table-header {
    flex: none !important;
}

.ant-table-body {
    flex: auto !important;
    overflow: scroll !important;
}
.ant-table-pagination {
    flex: none !important;
}

.sapDbId {
    background-color: #f0f5ff !important;
}



.ant-dropdown-menu-item-icon {
    min-width: 12px;
    margin-right: 8px;
    font-size: 20px !important;
}

